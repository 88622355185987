<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="suggestions"
      hide-default-footer
      class="elevation-1"
      mobile-breakpoint="300"
    >
      <template v-slot:top>
        <div class="d-flex justify-space-between align-center px-6 py-3">
          <span class="mx-3 text-dark-50 mt-1 font-size-h5 font-weight-bolder"
            >Suggestions</span
          >
          <button
            type="submit"
            class="btn btn-light-info"
            @click="showAddModal"
          >
            Add Suggestion
          </button>
        </div>
      </template>

      <template v-slot:item.zone_group_id="{ value }">
        {{ getZoneName(value) }}
      </template>

      <template v-slot:item.zones="{ value }">
        {{ value && value.map((item) => item.text).join(", ") }}
      </template>

      <template v-slot:item.locations="{ value }">
        {{ value && value.map((item) => item.text).join(", ") }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="() => showEditModal(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="() => remove(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <AddEditSuggestionsModal ref="suggestions" />
  </div>
</template>

<script>
import AddEditSuggestionsModal from "@/own/components/warehouseManagement/putawayRules/AddEditSuggestionsModal";
import { REMOVE_SUGGESTIONS } from "@/core/services/store/putawayRules.module";
export default {
  name: "SuggestionsDatatable",
  components: { AddEditSuggestionsModal },
  computed: {
    suggestions: function () {
      return this.$store.getters.getPUTAWAYRULESSuggestions;
    },
    headers: function () {
      return [
        {
          text: "Zone Group",
          value: "zone_group_id",
          align: "center",
          width: 220,
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Zones",
          value: "zones",
          align: "center",
          width: 320,
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Locations",
          value: "locations",
          align: "left",
          width: 270,
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Actions",
          value: "actions",
          align: "left",
          sortable: false,
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
      ];
    },
  },
  methods: {
    getZoneName(value) {
      let res;
      this.$store.getters.getPUTAWAYRULESServerData.zone_groups.forEach(
        (item) => {
          if (item.index === value) res = item.text;
        }
      );
      return res;
    },
    showEditModal(item) {
      const lastElementIndex = this.suggestions.findIndex(
        (elem) => elem.id === item.id
      );
      this.$refs.suggestions.initData(lastElementIndex, item);
    },
    showAddModal() {
      this.$refs.suggestions.initData(null, {
        id: null,
        zone_group_id: null,
        zones: null,
        locations: null,
      });
    },
    remove(item) {
      this.$store.commit(REMOVE_SUGGESTIONS, item);
    },
  },
};
</script>
