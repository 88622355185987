<template>
  <v-dialog v-model="dialog" persistent max-width="650">
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-3 align-center">
        <h5 class="mb-0 font-weight-bolder">{{ headerText }}</h5>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column h-350px scroll-y mx-5 py-7">
        <div class="h-75px">
          <v-autocomplete
            v-model="formData.zone_group_id"
            label="Zone group"
            :items="zoneGroups"
            item-text="text"
            item-value="index"
            dense
            outlined
            clearable
            hide-no-data
            hide-selected
            @change="handleChangeZoneGroup"
            :error-messages="zoneGroupErrors"
            @blur="$v.formData.zone_group_id.$touch()"
          />
        </div>
        <div class="h-75px">
          <v-combobox
            v-model="formData.zones"
            label="Zones"
            :items="zones"
            item-text="text"
            item-value="index"
            multiple
            dense
            outlined
            clearable
            small-chips
            deletable-chips
            hide-no-data
            hide-selected
            @change="handleChangeZone"
          />
        </div>
        <div class="h-75px">
          <v-combobox
            v-model="formData.locations"
            label="Locations"
            :items="locations"
            item-text="text"
            item-value="index"
            multiple
            dense
            outlined
            clearable
            small-chips
            deletable-chips
            hide-no-data
            hide-selected
          />
        </div>

        <!--begin::Actions-->
        <div class="mt-6 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  EDIT_SUGGESTIONS,
  SET_SUGGESTIONS,
} from "@/core/services/store/putawayRules.module";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "AddEditSuggestionsModal",
  mixins: [validationMixin],
  validations: {
    formData: {
      zone_group_id: { required },
    },
  },
  data: () => ({
    dialog: false,
    lastElementIndex: null,
    zones: [],
    locations: [],
    formData: {
      id: null,
      zone_group_id: null,
      zones: null,
      locations: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    handleChangeZoneGroup(event) {
      if (event) {
        this.$store.commit(SET_PAGE_LOADING, true);
        ApiService.post("/wms/warehouses/zones/search", {
          id: [event],
        }).then(({ data }) => {
          this.zones = data.zones;
          this.locations = [];
          this.formData.zones = null;
          this.formData.locations = null;
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      } else {
        this.zones = [];
        this.locations = [];
        this.formData.zones = null;
        this.formData.locations = null;
      }
    },
    handleChangeZone(event) {
      if (event.length > 0) {
        let selectedZones = event.map((item) => item.index);

        this.$store.commit(SET_PAGE_LOADING, true);
        ApiService.post("/wms/warehouses/location/search", {
          id: selectedZones,
        }).then(({ data }) => {
          this.locations = data.locations;
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      } else {
        this.locations = [];
        this.formData.locations = null;
      }
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.lastElementIndex !== null) {
        let data = {
          newItem: this.formData,
          lastElementIndex: this.lastElementIndex,
        };
        this.$store.commit(EDIT_SUGGESTIONS, data);
      } else this.$store.commit(SET_SUGGESTIONS, this.formData);

      this.toggleModal();
    },
    resetFormData() {
      this.$v.$reset();
      this.formData = {
        zone_group_id: null,
        zones: null,
        locations: null,
      };
    },
    toggleModal() {
      if (this.dialog) this.resetFormData();
      this.dialog = !this.dialog;
    },
    initData(index, newItem) {
      this.lastElementIndex = index;
      this.formData.id = newItem.id;
      this.formData.zone_group_id = newItem.zone_group_id;
      this.formData.zones = newItem.zones;
      this.formData.locations = newItem.locations;
      this.dialog = true;
    },
  },
  computed: {
    headerText: function () {
      if (this.lastElementIndex !== null) return "Edit Item";
      else return "Add New Item";
    },
    submitButtonText: function () {
      if (this.lastElementIndex !== null) return "Update";
      else return "Submit";
    },
    zoneGroups: function () {
      return this.$store.getters.getPUTAWAYRULESServerData.zone_groups;
    },
    zoneGroupErrors: function () {
      return this.handleFormValidation("zone_group_id");
    },
  },
};
</script>
